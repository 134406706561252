import {
  FocusEventHandler,
  FormEvent,
  ForwardedRef,
  KeyboardEvent,
  KeyboardEventHandler,
  ReactElement,
  ReactNode,
} from 'react';
import { Popup } from '../Popup';
import { Icon6DS } from '../SixSenseElements';
import './Input.scss';

interface InputProps {
  name: string;
  value: string | number | null | undefined;
  autoComplete?: boolean | 'off';
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorMsg?: string;
  fieldClassName?: string;
  forwardRef?: ForwardedRef<HTMLInputElement>;
  hint?: string;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  inputClassName?: string;
  inputId?: string;
  label?: string | { requiredStatus?: 'required' | 'optional'; text?: string };
  max?: string;
  maxLength?: number;
  min?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  onClick?: (e: FormEvent<HTMLInputElement>) => void;
  onEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e?: any) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  onLeftIconClick?: () => void;
  onRightIconClick?: () => void;
  open?: string;
  placeholder?: string;
  testId?: string;
  title?: string;
  tooltip?: ReactNode;
  type?: 'email' | 'password' | 'text' | 'number';
}

export const InputLabel = ({
  label,
  name,
  tooltip,
}: {
  label: InputProps['label'];
  name: string;
  tooltip?: ReactNode;
}) => {
  return label && typeof label === 'object' ? (
    <label
      className={`InputField__label ${label.requiredStatus && 'InputField__label--required'}`}
      data-testid="InputFieldLabel"
      htmlFor={name}
    >
      {label.text}{' '}
      {label.requiredStatus ? (
        <span className="InputField__requiredLabel">({label.requiredStatus})</span>
      ) : (
        ''
      )}
      {tooltip && (
        <Popup
          content={tooltip}
          trigger={
            <Icon6DS
              className="InputField__infoIcon"
              color="#384250"
              size="small"
              type="MdInfoOutline"
            />
          }
        />
      )}
    </label>
  ) : (
    <label className="InputField__label" data-testid="InputFieldLabel" htmlFor={name}>
      {label}
      {tooltip && (
        <Popup
          content={tooltip}
          trigger={
            <Icon6DS
              className="InputField__infoIcon"
              color="#384250"
              size="small"
              type="MdInfoOutline"
            />
          }
        />
      )}
    </label>
  );
};

export const Input = ({
  fieldClassName = '',
  value,
  className = '',
  inputClassName = '',
  inputId,
  label,
  name,
  title,
  hint,
  iconLeft,
  iconRight,
  open,
  type,
  placeholder,
  autoComplete = false,
  autoFocus,
  error,
  errorMsg,
  disabled,
  tooltip,
  testId = 'Input',
  maxLength,
  onEnter,
  onFocus,
  onBlur,
  onKeyUp,
  onKeyDown,
  onChange,
  onLeftIconClick,
  onRightIconClick,
  forwardRef,
  ...props
}: InputProps) => {
  const openSide = open === 'left' ? 'Input--leftOpen' : open === 'right' ? 'Input--rightOpen' : '';
  const inputPlaceholder = placeholder || '';
  const leftIconIsClickable = !!onLeftIconClick;
  const rightIconIsClickable = !!onRightIconClick;
  const isReadOnly = !onChange;
  const isRequired = typeof label === 'object' && label.requiredStatus === 'required';
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onEnter && onEnter(e);
    } else {
      onKeyDown && onKeyDown(e);
    }
  };

  return (
    <div className={`InputField ${className}`} data-testid="InputField">
      {label && (
        <div className="InputField__title">
          <InputLabel label={label} name={name} tooltip={tooltip} />
          {maxLength && typeof value !== 'number' && (
            <div className="InputField__count" data-testid="InputField__count">
              <span className="InputField__countText">
                {value?.length || 0}/{maxLength}
              </span>
            </div>
          )}
        </div>
      )}
      <div className={`InputField__field ${fieldClassName}`}>
        {iconLeft && (
          <div
            className={`InputField__icon InputField__icon--left ${
              leftIconIsClickable ? 'InputField__icon--actionable' : ''
            }`}
            data-testid="InputLeftIcon"
            onClick={onLeftIconClick}
          >
            {iconLeft}
          </div>
        )}
        <input
          {...props}
          autoComplete={`${autoComplete}`}
          autoFocus={autoFocus}
          className={`Input ${error ? 'Input--error' : ''} ${inputClassName} ${openSide}`}
          data-testid={testId}
          disabled={disabled}
          id={inputId || name}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          onKeyUp={onKeyUp}
          placeholder={inputPlaceholder}
          readOnly={isReadOnly}
          ref={forwardRef}
          required={isRequired}
          title={title}
          type={type || 'text'}
          value={value ? value : ''}
        />
        {iconRight && (
          <div
            className={`InputField__icon InputField__icon--right ${
              rightIconIsClickable ? 'InputField__icon--actionable' : ''
            }`}
            data-testid="InputRightIcon"
            onClick={onRightIconClick}
          >
            {iconRight}
          </div>
        )}
      </div>
      {error ? (
        <p className="InputField__errorText" data-testid={`${testId}Error`}>
          {errorMsg ?? hint}
        </p>
      ) : hint ? (
        <p className="InputField__hintText" data-testid={`${testId}Hint`}>
          {hint}
        </p>
      ) : null}
    </div>
  );
};
