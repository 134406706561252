import { BotEmailProvider, BotSchemaWithWarmup, CrmType, TeamSchema } from 'app/schemas';
import _camelcaseKeys from 'camelcase-keys';
import { format } from 'date-fns';
import DOMPurify from 'isomorphic-dompurify';
import { capitalize, startCase, upperCase, upperFirst } from 'lodash';
import moment, { Moment } from 'moment';
import { IStringifyOptions, stringify } from 'qs';
import _snakecaseKeys from 'snakecase-keys';
import { ROLE_LABELS, SIXSENSE_INBOX_DISPLAY_NAME } from '../../config/constants';

export function snakecaseKeys(body = {}, options?: _snakecaseKeys.Options) {
  return _snakecaseKeys(body, { exclude: [/__/g], ...options }); // Special handling for object keys with more than two underscores -  Exclude keys from being camelcased.
}
export function camelcaseKeys(data: any, options?: _camelcaseKeys.Options) {
  return _camelcaseKeys(data, { exclude: [/__/g], ...options }); // Special handling for object keys with more than two underscores -  Exclude keys from being snakecased
}
export const formattedDate = (
  d: Date | string | null | undefined,
  placeholder = 'N.A.',
  f = 'MMM d, yyyy'
) => formattedDateTime(d, placeholder, f);
export const formattedDateTime = (
  d: Date | string | null | undefined,
  placeholder = 'N.A.',
  f = 'MMM d, yyyy h:mm a'
) => {
  if (!d) {
    return placeholder;
  }
  if (typeof d === 'string') {
    const date = new Date(d);
    return format(date, f);
  }
  return format(d, f);
};
export const formattedMoment = (m: Moment = moment(new Date()), f = 'yyyy-MM-dd') => {
  return format(m.toDate(), f);
};
export const formattedDisplayName = (
  f: string | null | undefined,
  l: string | null | undefined
) => {
  let firstName = f;
  let lastName = l;

  if (f === null || f === undefined || (f && f.match(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g))) {
    firstName = '';
  }
  if (l === null || l === undefined || (l && l.match(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g))) {
    lastName = '';
  }

  return `${firstName} ${lastName}`.trim();
};

export const formattedBytes = (bytes: number = 0, showDecimal: boolean = true) => {
  if (!bytes) return '0 KB';
  const k = 1000,
    sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return showDecimal
    ? parseFloat((bytes / Math.pow(k, i)).toString()).toFixed(2) + ' ' + sizes[i]
    : parseFloat((bytes / Math.pow(k, i)).toString()) + ' ' + sizes[i];
};
export const formattedFileTypes = (s: string) => {
  const files: { [k: string]: string } = {
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'doc',
    'application/vnd.ms-word.document.macroEnabled.12': 'doc',
    'application/vnd.ms-word.template.macroEnabled.12': 'doc',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'xls',
    'application/vnd.ms-excel.sheet.macroEnabled.12': 'xls',
    'application/vnd.ms-excel.template.macroEnabled.12': 'xls',
    'application/vnd.ms-excel.addin.macroEnabled.12': 'xls',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12': 'xls',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.template': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'ppt',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12': 'ppt',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12': 'ppt',
    'application/vnd.ms-powerpoint.template.macroEnabled.12': 'ppt',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': 'ppt',
    'application/vnd.ms-access': 'mdb',
    'application/x-iwork-keynote-sffkey': 'key',
    'application/x-iwork-pages-sffpages': 'page',
    'application/x-iwork-numbers-sffnumbers': 'num',
    'application/vnd.apple.keynote': 'key',
    'application/vnd.apple.pages': 'page',
    'application/vnd.apple.numbers': 'num',
    'application/zip': 'zip',
    'application/x-rar-compressed': 'zip',
    'application/x-7z-compressed': 'zip',
    'application/pdf': 'pdf',
    'text/plain': 'txt',
    'text/csv': 'csv',
    'image/jpg': 'jpg',
    'image/jpeg': 'jpg',
    'image/pjpeg': 'jpg',
    'image/png': 'png',
    'image/x-png': 'png',
    'image/gif': 'gif',
  };
  return files[s];
};

// this formats an object to a query string in the format that BE expects i.e. ?list_a=1&list_a=2&list_a=3&list_b[]=1&list_b[]=2&list_b[]=3&list_c=1,2,3
export const stringifyToQueryString = (params: any, config?: IStringifyOptions) => {
  const snakeCasedParams = snakecaseKeys(params, { deep: true });
  return stringify(snakeCasedParams, {
    addQueryPrefix: true,
    arrayFormat: 'brackets',
    encode: false,
    ...config,
  });
};
// Example: Some default safe tags (similar to sanitize-html defaults)
const DEFAULT_ALLOWED_TAGS = [
  'h3',
  'h4',
  'h5',
  'h6',
  'blockquote',
  'p',
  'a',
  'ul',
  'ol',
  'nl',
  'li',
  'b',
  'i',
  'strong',
  'em',
  'strike',
  'code',
  'hr',
  'br',
  'div',
  'table',
  'thead',
  'caption',
  'tbody',
  'tr',
  'th',
  'td',
  'pre',
  'iframe',
  'img',
  'svg',
  'path',
  'span',
  'button',
  'g',
  'circle',
];
// Example: Some default safe attributes
const DEFAULT_ALLOWED_ATTRS = [
  'href',
  'name',
  'target',
  'src',
  'srcset',
  'alt',
  'title',
  'width',
  'height',
  'viewBox',
  'xmlns',
  'fill',
  'd',
];

const CUSTOM_ATTRS = [
  'data-merge-tag-attribute',
  'data-content-id',
  'data-personalization-fields',
  'data-coaching-instructions',
  'data-testid',
  'data-type',
];

const CUSTOM_TAGS = [
  'merge-tag',
  'dynamic-content',
  'ai-block',
  'email-divider',
  'text-to-cite',
  'snippet-merge-tag',
];
export function htmlSanitizer(html: string) {
  const config: DOMPurify.Config = {
    ADD_TAGS: [...DEFAULT_ALLOWED_TAGS, ...CUSTOM_TAGS],
    ADD_ATTR: [...DEFAULT_ALLOWED_ATTRS, ...CUSTOM_ATTRS],
  };

  return DOMPurify.sanitize(html, config);
}

/** Removes extra space in a string */
export const removeExtraSpace = (str: string) => {
  return str.replace(/\s{2,}/g, ' ').trim();
};

// prepends protocol prefix to beginning of url if not specified - for links
export function formatUrl(link: string) {
  if (link.startsWith('mailto:')) return link;
  return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
}

export function generateSplitTestVariantName(index: number) {
  return `Email ${String.fromCharCode(index + 65)}`;
}

export function parseHtmlString(str: string) {
  const sanitizedStr = htmlSanitizer(str);
  return new DOMParser().parseFromString(sanitizedStr, 'text/html');
}

// we could expand this to handle all sub-strings that should be uppercased eg: GPT-4
export function formatStringWithAI(str: string) {
  // regex looks ahead and looks back to cover non-alphanumeric chars and account for start / end of string
  // UPDATE[AUG-2023]:
  // Currently we cannot use lookbehind in regex as Safari does not support it in their older versions
  // Instead we can capture groups and build the string from there
  // before and ai below are the captured groups
  const replaceRegex = new RegExp(/(^|\W)(ai)(?=$|\W)/gi);
  return str.replace(replaceRegex, (_, before: string, ai: string) => before + upperCase(ai));
}

//Truncate string to keep specified number of first words
//Aside from the split, there's an extra .replace() so that subsequent white spaces don't count as extra words
export function truncateSentence(str: string, noOfWords: number) {
  const words = str.replace(/\s+/g, ' ').split(/(?=\s)/gi);
  return `${words.slice(0, noOfWords).join('')}${words.length > noOfWords ? '...' : ''}`;
}

export function formattedTeamRole(role: string) {
  return ROLE_LABELS[role as 'member' | 'admin' | 'view_only_admin'] || ROLE_LABELS.member;
}
/**
 * Format text enclosed within double asterisks (**) to be displayed as bold
 * @param {string} text The input text
 * @returns The formatted text with bold styling
 * Before: **People Profile** Our ideal prospect is a sales or marketing professional
 * After: <strong>People Profile</strong> Our ideal prospect is a sales or marketing professional
 */
export function formatTextWithDoubleAsterisks(text: string) {
  return text.replace(/\*\*(\S(.*?\S)?)\*\*/gm, '<strong>$1</strong>');
}

export function generateCrmReportLabel(crmType: CrmType, format: 'full' | 'shorthand' = 'full') {
  const t_crm = crmType === 'hubspot' ? 'HubSpot ' : 'Salesforce ';
  const t_reportLabel = crmType === 'hubspot' ? 'contact list' : 'report';
  return `${format === 'full' ? t_crm : ''}${t_reportLabel}`;
}
export function generateCrmLabel(crmType: CrmType) {
  return crmType === 'hubspot' ? 'HubSpot' : 'Salesforce';
}

export function generateTerminalStatusFromClassificationValue(
  value: string
): 'not_interested' | 'qualified' | 'not_interested_now' {
  switch (value) {
    case 'negative_intent':
      return 'not_interested';
    case 'qualified_positive_intent_(goal_conversion)':
      return 'qualified';
    default:
      return 'not_interested_now';
  }
}

export function stripToEmailString(email: string) {
  const _email = /^[^<]*<(.*)>$/.exec(email);

  return _email ? _email[1].toLowerCase() : email;
}

export function removeErrantCharacters(email: string) {
  const _email = email.replace(/"/g, '');

  return _email.replace(/,/g, ' ');
}

export function camelCaseToSentenceCase(text: string) {
  return text.replace(/^[a-z]|[A-Z]/g, function (v, i) {
    return i === 0 ? v.toUpperCase() : ' ' + v.toLowerCase();
  });
}

export function lowerCaseExceptFirstWord(w: string) {
  return w
    .split(' ')
    .map((w, i) => (i === 0 ? w : w.toLowerCase()))
    .join(' ');
}

export function hasWhiteSpace(str: string) {
  return /\s/g.test(str);
}

export function contactValidationDecorator(currentTeam: TeamSchema) {
  const {
    doNotEnrollDisposableDomainsPref,
    doNotEnrollEmailsWithNoMxRecordsPref,
    doNotEnrollFreeDomainsPref,
    doNotEnrollRoleBasedUsernamesPref,
  } = currentTeam;
  return [
    {
      enabled: doNotEnrollFreeDomainsPref,
      label: 'Don’t automatically enroll contacts with personal emails',
      subLabel:
        'Personal emails might not be appropriate contact points, depending on the audience you would like to reach out to.',
      name: 'Personal',
    },
    {
      enabled: doNotEnrollRoleBasedUsernamesPref,
      label: 'Don’t automatically enroll contacts with role-based emails',
      subLabel:
        'Role based email addresses are usually not associated to individuals, and might not accept any inbound emails.',
      name: 'RoleBased',
    },
    {
      enabled: doNotEnrollDisposableDomainsPref,
      label: 'Don’t automatically enroll contacts with disposable emails',
      subLabel:
        'Disposable emails might self destruct in the future, and contribute to bounce rates.',
      name: 'Disposable',
    },
    {
      enabled: doNotEnrollEmailsWithNoMxRecordsPref,
      label: 'Don’t automatically enroll contacts with invalid email servers',
      subLabel:
        'Contacts might be associated with invalid domains, and any emails inbound will bounce.',
      name: 'Invalid',
    },
  ];
}

export function formatEmailProvider(str: string) {
  switch (str) {
    case BotEmailProvider.GMAIL:
      return 'Google';
    case BotEmailProvider.OUTLOOK:
      return 'Outlook';
    case BotEmailProvider.SENDGRID:
      return 'SendGrid';
    default:
      return 'email provider';
  }
}

export function formatCampaignPauseReason(reason: string) {
  return capitalize(startCase(reason)).replace(/^Ai/i, 'AI');
}

export function getBotConnectionStatus({ bot }: { bot: BotSchemaWithWarmup | null }) {
  return bot?.state === 'disconnected'
    ? 'disconnected'
    : bot?.restricted
    ? 'restricted'
    : bot?.state === 'connected'
    ? 'connected'
    : '';
}

export function formatBotProvider(provider: string) {
  switch (provider) {
    case BotEmailProvider.SIXSENSE_INBOX:
      return SIXSENSE_INBOX_DISPLAY_NAME;
    case 'send_grid':
      return 'SendGrid';
    default:
      return upperFirst(provider);
  }
}
export function formatTimeZone(timeZone: string | null) {
  return timeZone ? timeZone.replaceAll(/_/g, ' ') : '—';
}
